import { createStore } from 'vuex'


function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return (arr[2]);
  else
    return null;
}

//设置cookie,增加到vue实例方便全局调用
function setCookie (c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
};

//设置本地缓存，带过期时间
function setLocalItem(key,value,expiredTimeMS){
  if((expiredTimeMS == 0 )  || (expiredTimeMS == null)){
    localStorage.setItem(key,value);
  }
  else {
    localStorage.setItem(key, JSON.stringify(value));
    localStorage.setItem(key+"cacheExpiredTime",expiredTimeMS);
    localStorage.setItem(key+"cacheExpiredStartTime",new Date().getTime());
  }
};


//移除本地缓存
function removeLocalItem(key){
  localStorage.removeItem(key);
  localStorage.removeItem(key+"cacheExpiredTime");
  localStorage.removeItem(key+"cacheExpiredStartTime");
}

//读取本地缓存
function getLocalItem(key){
  var expiredTimeMS = localStorage.getItem(key+"cacheExpiredTime");
  var expiredStartTime = localStorage.getItem(key+"cacheExpiredStartTime");
  var curTime = new Date().getTime();

  var sum = Number(expiredStartTime)  + Number(expiredTimeMS);

  if((sum) > curTime){
    // console.log("cache-缓存["+key+"]存在！");
    
    return  JSON.parse(localStorage.getItem(key));
  }else {
    //过期了
    // console.log("cache-缓存["+key+"]不存在！");
    return null;
  }
};

export default createStore({
  state: {
    token: getLocalItem('token')||'',
    userInfo: getLocalItem('userInfo') || {},
    searchVal: getLocalItem('searchVal') || {name:'',id:'',keyword:''},

    account: getCookie("account") || ''
  },
  mutations: {
    updateLoginStatus(state, payload){  //登录状态，原来系统使用JWT登录时，用于保存token，但现在已经不再使用；
      state.token = payload.token;
      setLocalItem('token', payload.token, 14400000);
    },
    updateUserInfo(state, payload){
      state.userInfo = payload.userInfo;
      // console.log('更新用户信息', getLocalItem('userInfo'));
      setLocalItem('userInfo', payload.userInfo, 14400000);
    },
    updateSearch(state, payload){
      state.searchVal = payload.search;
      setLocalItem('searchVal', payload.search, null);
    },
    delSearch(){
      sessionStorage.removeItem('searchVal');
    },
    updateAccount(state, payload){
      state.account = payload.account;
      setCookie("account", state.account, 90);
    }
  },
  actions: {
  },
  modules: {
  }
})
